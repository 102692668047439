import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./context/store";
import "./index.css";

import Email from "./containers/Email";
import Navs from "./containers/Shell";
import TaskManager from "./containers/TaskManager";

const App = () => {
	return (
		<Provider store={store}>
			<Router>
				<Routes>
					<Route path="/email-logs" element={<Email />} />
					<Route path="/Shell" element={<Navs />} />
					<Route path="/task-manager" element={<TaskManager />} />
				</Routes>
			</Router>
		</Provider>
	);
};

export default App;
