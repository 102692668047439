import axios from "axios";

let headers = {
	Origin: window.location.href,
	"Access-Control-Allow-Origin": "*",
};

const axiosIstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers,
});

const axiosIstanceTaskManager = axios.create({
	baseURL: process.env.REACT_APP_TASK_MANAGER,
	headers,
});

export { axiosIstance, axiosIstanceTaskManager };
