import { axiosIstance, axiosIstanceTaskManager } from "../helpers/axios";

export const getEmails = (customerId, endDate, startDate, size, page) => {
	let params = {
		customerId: customerId,
		endDate: endDate,
		startDate: startDate,
		size: size,
		page: page,
	};

	return axiosIstance.get("/emails", { params }).catch((err) => {
		console.log(err);
	});
};

export const getCustomerId = (token, companyId) => {
	let params = {
		userToken: token,
		companyId: companyId,
	};

	return axiosIstance.get("/me", { params }).catch((err) => {
		console.log(err);
	});
};

export const getVendorTasks = (cid) => {
	let params = {
		cid: cid,
	};

	return axiosIstanceTaskManager
		.get("api/task-manager/vendor-task", { params })
		.catch((err) => {
			console.log(err);
		});
};

export const updateVendorTasks = (cid, row_id, data) => {
	let params = {
		cid: cid,
	};

	console.log(cid, row_id, data);

	return axiosIstanceTaskManager
		.put(`api/task-manager/vendor-task/${row_id}`, data, { params })
		.catch((err) => {
			console.log(err);
		});
};

export const deleteVendorTasks = (cid, row_id) => {
	let params = {
		cid: cid,
	};

	console.log(cid, row_id);

	return axiosIstanceTaskManager
		.delete(`api/task-manager/vendor-task/${row_id}`, { params })
		.catch((err) => {
			console.log(err);
		});
};

export const createVendorTasks = (cid, data) => {
	let params = {
		cid: cid,
	};

	return axiosIstanceTaskManager
		.post("api/task-manager/vendor-task/", data, { params })
		.catch((err) => {
			console.log(err);
		});
};
