import React from "react";
import { Table } from "antd";

import "antd/dist/antd.css";
const DynamicTable = ({
	columnHeader,
	data,
	tableProps,
	pagination,
	scroll,
	loading,
	onChange,
	expandedRowRender,
	rowSelection,
	onRow,
}) => {
	return (
		<div className="data-table">
			<Table
				{...tableProps}
				dataSource={data}
				columns={columnHeader}
				pageSizeOptions={[25, 35, 50, 100]}
				pagination={pagination}
				scroll={scroll}
				loading={loading}
				onChange={onChange}
				expandedRowRender={expandedRowRender}
				rowSelection={rowSelection}
				onRow={onRow}
			/>
		</div>
	);
};
export default DynamicTable;
