import { combineReducers } from "redux";
import { emailReducer } from "./emailReducer";
import { userReducer } from "./userReducer";
import { taskManagerReducer } from "./taskManagerReducer";

const reducers = combineReducers({
	allEmails: emailReducer,
	allUsers: userReducer,
	allTasks: taskManagerReducer,
});

export default reducers;
