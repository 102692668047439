import React, { useEffect, useState } from "react";
import { Table, Input, InputNumber, Form } from "antd";

const inputNode = (inputType) => {
	switch (inputType) {
		case "text":
			return <Input />;
		case "number":
			return <InputNumber />;
		default:
			return <Input />;
	}
};

const EditableCell = ({
	editing,
	dataIndex,
	title,
	inputType,
	inputProps,
	record,
	index,
	children,
	...restProps
}) => {
	const field = inputNode(inputType, inputProps, dataIndex, record);
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0,
					}}
					rules={[
						{
							required: true,
							message: `Please Input ${title}!`,
						},
					]}
				>
					{field}
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const EditableTable = (props) => {
	const {
		columnHeader,
		originData,
		expandedRowRender,
		rowSelection,
		scroll,
		isLoading,
		pageSize,
	} = props;

	const [form] = Form.useForm();
	const [data, setData] = useState([]);
	const [editingKey, setEditingKey] = useState("");

	const isEditing = (record) => record.id === editingKey;

	const edit = (record) => {
		form.setFieldsValue({
			...record,
		});
		setEditingKey(record.id);
	};

	const cancel = () => {
		setEditingKey("");
	};

	const save = async (key) => {
		try {
			const row = await form.validateFields();
			const newData = [...data];
			const index = newData.findIndex((item) => key === item.id);

			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, { ...item, ...row });
				setData(newData);

				setEditingKey("");
			} else {
				newData.push(row);
				setData(newData);
				setEditingKey("");
			}
		} catch (errInfo) {
			console.log("Validate Failed:", errInfo);
		}
	};

	const mergedColumns = columnHeader.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.inputType ? col.inputType : "text",
				inputProps: col.inputProps,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	useEffect(() => {
		setData(originData);
	}, [originData]);

	return (
		<Form form={form} component={false}>
			<Table
				components={{
					body: {
						cell: EditableCell,
					},
				}}
				dataSource={data}
				columns={mergedColumns}
				rowClassName="editable-row"
				pagination={{
					onChange: cancel,
					pageSize: pageSize,
				}}
				expandedRowRender={expandedRowRender}
				rowSelection={rowSelection}
				scroll={scroll}
				onRow={(record) => {
					return {
						onClick: () => {
							isEditing(record);
							edit(record);
						}, // click row

						onMouseLeave: () => {
							save(record.id);
						},
					};
				}}
				loading={isLoading}
				// rowKey="id"
			/>
		</Form>
	);
};

export default EditableTable;
