import _ from "underscore";

class SentTypeEnum {
	constructor(key, dscp, color, bgColor) {
		this.key = key;
		this.dscp = dscp;
		this.color = color;
		this.bgColor = bgColor;
	}
}
const toReturn = {
	ORIGINAL: new SentTypeEnum("original", "Original", "#00a3ed", "#ddf4ff"),
	PASSBACK: new SentTypeEnum("passback", "Passback", "#e6bf00", "#fbf6e1"),
	MANUAL_NUDGE: new SentTypeEnum(
		"manual_nudge",
		"Manual Nudge",
		"#7f39b2",
		"#f0e4f9"
	),
	AUTO_NUDGE: new SentTypeEnum(
		"auto_nudge",
		"Auto Nudge",
		"#51c8c4",
		"#e2fffe"
	),
	WORKSTEP_EXTERNAL: new SentTypeEnum(
		"workstep_external",
		"Workstep External",
		"#51c8c4",
		"#e2fffe"
	),
};

const methods = {};

methods.getList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o);
		}
	});
	return keyList;
};

methods.getKeyList = () => {
	let keyList = [];

	Object.values(toReturn).forEach((o) => {
		if (o.key !== undefined) {
			keyList.push(o.key);
		}
	});
	return keyList;
};

methods.getDscpFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});
	return found ? found.dscp : "";
};

methods.getColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.color : "";
};

methods.getBgColorFromKey = (key) => {
	let found = _.find(Object.values(toReturn), (o) => {
		if (o.key === key) {
			return true;
		}

		return false;
	});

	return found ? found.bgColor : "";
};

export default Object.freeze({ ...toReturn, ...methods });
