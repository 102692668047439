import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableTable from "../components/EditableTable";
import {
	getVendorTasks,
	createVendorTasks,
	updateVendorTasks,
	deleteVendorTasks,
} from "../services/baseAPI";

import { Checkbox, Input, Button, Popconfirm, message } from "antd";
import {
	CopyOutlined,
	DeleteOutlined,
	HistoryOutlined,
} from "@ant-design/icons";
import { setTasks } from "../context/actions/taskManagerAction";
// const { Option } = Select;

const TaskManagerTable = () => {
	const tasks = useSelector((state) => state.allTasks.tasks);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();

	const columns = [
		{
			title: "Action",
			key: "",
			fixed: "left",
			width: 90,
			render: (item) => (
				<span className="table-action">
					<Popconfirm
						placement="bottom"
						title={"Are you sure you want to copy row?"}
						onConfirm={onClickCopy(item)}
						okText="Yes"
						cancelText="No"
					>
						<CopyOutlined />
					</Popconfirm>
					<Popconfirm
						placement="bottom"
						title={"Are you sure you want to delete?"}
						onConfirm={onClickRemove(item)}
						okText="Yes"
						cancelText="No"
					>
						<DeleteOutlined />
					</Popconfirm>

					<HistoryOutlined onClick={onClickHistory(item)} />
				</span>
			),
		},
		{
			title: "",
			key: "",
			fixed: "left",
			width: 50,
			render: () => (
				<span className="table-action">
					<Checkbox defaultChecked={false} onChange={onChangeCheckbox} />
				</span>
			),
		},
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			fixed: "left",
		},
		{
			title: "Vendor Name",
			dataIndex: "g_name",
			key: "g_name",
			fixed: "left",
		},
		{
			title: "Subsidiary",
			dataIndex: "g_subsidiary",
			key: "g_subsidiary",
			editable: true,
		},
		{
			title: "Department",
			dataIndex: "g_department_debit",
			key: "g_department_debit",
			editable: true,
		},
		{
			title: "GLAccount",
			dataIndex: "g_glaccount_debit",
			key: "g_glaccount_debit",
			editable: true,
		},
		{
			title: "External Accrual",
			dataIndex: "g_external_accrual",
			key: "g_external_accrual",
			editable: true,
		},
		{
			title: "Internal Accrual",
			dataIndex: "g_internal_accrual",
			key: "g_internal_accrual",
			editable: true,
		},
		{
			title: "Calculated Accrual",
			dataIndex: "g_calculated_accruals",
			key: "g_calculated_accruals",
			editable: true,
		},
		{ title: "Reviewed", dataIndex: "g_reviewed", key: "g_reviewed" },
		{
			title: "",
			dataIndex: "",
			key: "",
			render: (item) => (
				<Button type="primary" onClick={onClickUpdate(item)}>
					Update
				</Button>
			),
		},
	];

	const onClickUpdate = (item) => () => {
		console.log("update", item);
		updateVendorTasks(122, item.id, item).then(() => {
			message.success("Success updating row");
		});
	};

	const onHandleChangeRow = (item) => {
		console.log("updaterow", item);
	};

	const onClickRemove = (item) => () => {
		console.log("remove", item);
		deleteVendorTasks(122, item.id).then((res) => {
			console.log(res);
			window.location.reload(false);
		});
	};

	const onClickHistory = (item) => () => {
		console.log("history", item);
	};

	const onClickCopy = (item) => () => {
		console.log("copy", item);
		const { id, ...newItem } = item;
		console.log("copy2", newItem);
		createVendorTasks(122, newItem).then((res) => {
			console.log(res);
			window.location.reload(false);
		});
	};

	const onChangeFilter = (key) => (e) => {
		let text = e.target.value;
		let filteredData = [];
		console.log("filter", key, text);
		if (text === "") {
			getTaskList(122);
		} else {
			tasks.forEach((item) => {
				if (item[key].toString().toUpperCase().includes(text.toUpperCase())) {
					filteredData.push(item);
					dispatch(setTasks(filteredData));
				}
			});
		}
	};

	// const onChangeSelector = (key, row) => (value) => {
	// 	row[key] = value;
	// };

	const onChangeCheckbox = (key, row) => (e) => {
		let value = e.target.checked;
		row[key] = value;
	};

	const getTaskList = async (cid) => {
		const response = await getVendorTasks(cid);
		//55

		if (response) {
			dispatch(setTasks(response.data.content));
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getTaskList(122); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="table-content-wrapper">
			<div className="filter-wrapper">
				<Input placeholder="Vendor Name" onChange={onChangeFilter("g_name")} />
				<Input
					placeholder="Subsidiary"
					onChange={onChangeFilter("g_subsidiary")}
				/>
				<Input
					placeholder="Department"
					onChange={onChangeFilter("g_department_debit")}
				/>
				<Input
					placeholder="GLAccount"
					onChange={onChangeFilter("g_glaccount_debit")}
				/>
				<Input
					placeholder="External Accrual"
					onChange={onChangeFilter("g_external_accrual")}
				/>
				<Input
					placeholder="Internal Accrual"
					onChange={onChangeFilter("g_internal_accrual")}
				/>
				<Input
					placeholder="Calculated Accrual"
					onChange={onChangeFilter("g_calculated_accruals")}
				/>
				<Input placeholder="Reviewed" onChange={onChangeFilter("g_reviewed")} />
			</div>
			<EditableTable
				columnHeader={columns}
				originData={tasks}
				scroll={{ x: 1500 }}
				loading={isLoading}
				pageSize={50}
				onSaveRow={onHandleChangeRow}
			/>
		</div>
	);
};

export default TaskManagerTable;
